

const zoneData = [
  'Amazon',
  'Azure',
  'Google Cloud',
  'Paloalto',
  'SAP',
  'Oracle',
  'Okta',
];

const AiWorkflowData = [
  {
    id: '990032',
    itemName: 'Collect & Prepare Data',
    itemOptions: [
      {
        name: 'CVE-2022-28391',
        project: 'New Project',
        security: 'High',
        detailedName: 'busybox-initramfs',
        version: '1:1.30.1-7ubuntu3',
        detectionMethod: 'Package',
        source: 'Linode:Linode Wazuh Query',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28399',
        project: 'New Project',
        security: 'High',
        detailedName: 'openstack',
        version: '21.0.1-0ubuntu1~cloud0',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28392',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'nginx',
        version: '1.21.3-1ubuntu1.4',
        detectionMethod: 'Package',
        source: 'AWS:AWS WAF',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28393',
        project: 'New Project',
        security: 'Low',
        detailedName: 'wordpress',
        version: '5.8.1+dfsg1-1ubuntu2.1',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
    ],
    children: [
      'Access Control',
      'Data Poisoning, Leakage, Exfilteration',
      'Data Lineage',
      'Data Integrity & Privacy',
      'Adversarial Attacks',
      'Regulatory Compliance and Governance',
    ],
  },
  {
    id: '990033',
    itemName: 'Model Design & Development',
    itemOptions: [
      {
        name: 'CVE-2022-28392',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'nginx',
        version: '1.21.3-1ubuntu1.4',
        detectionMethod: 'Package',
        source: 'AWS:AWS WAF',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28399',
        project: 'New Project',
        security: 'High',
        detailedName: 'openstack',
        version: '21.0.1-0ubuntu1~cloud0',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28394',
        project: 'New Project',
        security: 'Critical',
        detailedName: 'tensorflow',
        version: '2.6.0-1',
        detectionMethod: 'Package',
        source: 'Google Cloud:Google Cloud Security Command Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28395',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'apache',
        version: '2.4.48-3+ubuntu18.04.1+deb.sury.org+1',
        detectionMethod: 'Package',
        source: 'AWS:AWS WAF',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
    ],
    children: [
      'Classification, Label flipping',
      'Model Training/Evaluation Poisoning',
      'Backdoor Machine Learning/Trojan Model',
      'Bias and Fairness',
      'Model Supply-Chain Risk',
      'Adversarial Attacks',
      'Feature Manipulation',
    ],
  },
  {
    id: '990034',
    itemName: 'Model Deployment, Monitoring, & Management',
    itemOptions: [
      {
        name: 'CVE-2022-28393',
        project: 'New Project',
        security: 'Low',
        detailedName: 'wordpress',
        version: '5.8.1+dfsg1-1ubuntu2.1',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28399',
        project: 'New Project',
        security: 'High',
        detailedName: 'openstack',
        version: '21.0.1-0ubuntu1~cloud0',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28396',
        project: 'New Project',
        security: 'High',
        detailedName: 'mysql',
        version: '8.0.27-0ubuntu0.20.04.1',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28397',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'mongodb',
        version: '4.4.11',
        detectionMethod: 'Package',
        source: 'Google Cloud:Google Cloud Security Command Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
    ],
    children: [
      'Prompt Injection, Output Validation',
      'Model Theft, Evasion,and Extraction',
      'Denial of Service (DoS) Attacks',
      'Privacy Risks',
      'Model Inference Attacks',
      'Model Inversion Attacks',
      'Model Drift, Bias, and Fairness',
      'Regulatory Compliance and Governance',
    ],
  },
  {
    id: '990035',
    itemName: 'Infrastructure and Operations',
    itemOptions: [
      {
        name: 'CVE-2022-28394',
        project: 'New Project',
        security: 'Critical',
        detailedName: 'tensorflow',
        version: '2.6.0-1',
        detectionMethod: 'Package',
        source: 'Google Cloud:Google Cloud Security Command Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28395',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'apache',
        version: '2.4.48-3+ubuntu18.04.1+deb.sury.org+1',
        detectionMethod: 'Package',
        source: 'AWS:AWS WAF',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28396',
        project: 'New Project',
        security: 'High',
        detailedName: 'mysql',
        version: '8.0.27-0ubuntu0.20.04.1',
        detectionMethod: 'Package',
        source: 'Azure:Azure Security Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28397',
        project: 'New Project',
        security: 'Critical',
        detailedName: 'docker',
        version: '20.10.12-0ubuntu1~20.04.2',
        detectionMethod: 'Package',
        source: 'Google Cloud:Google Cloud Security Command Center',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
      {
        name: 'CVE-2022-28398',
        project: 'New Project',
        security: 'Medium',
        detailedName: 'kubernetes',
        version: '1.22.4-00',
        detectionMethod: 'Package',
        source: 'AWS:AWS WAF',
        dataSourceLink: { linkName: 'nvd.nist.gov', link: '/nvd.nist.gov' },
      },
    ],
    children: [
      'Infrastructure Vulnerabilities',
      'Cloud and GenAI systems Resiliency',
      'Third-Party Risks',
      'Access Control',
      'Security Posture',
      'Regulatory Compliance and Governance',
    ],
  },
];

export {zoneData , AiWorkflowData}
