import { Stack, Text, Button, Box, InputGroup, Input, Textarea, InputLeftAddon, Select, InputLeftElement, InputRightElement, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import LocationHeader from '../components/LocationHeader/index.js';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import CloudOptionButton from '../components/CloudComponents/CloudOptionButton.js';
import ZeroTrustPosture from '../components/DesiredOutcomes/ZeroTrustPosture.js';
import { useState, useEffect } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import HostCloudButton from '../components/CloudComponents/HostCloudButton.js';
import {
  hostCloud,
  solutionAmzaonOpt,
  solutionBuilderdata,
  solutionAzureOpt,
} from '../utilities/solutionBuilderData.js';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { AiOutlineQuestionCircle } from "react-icons/ai";
import SpacedStack from '../components/SpacedStack/index.js';
import SolutionCard from "../components/SolutionCard/SolutionCard.js";
import { GrDocument } from "react-icons/gr";
import { FiChevronDown } from "react-icons/fi"; import { Checkbox } from '@chakra-ui/react'

import SolutionSubOptBox from '../components/SolutionBuilder/SolutionSubOptBox.js';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

export default function SolutionBuilder() {
  const navigation = useNavigate();
  const [select, setSelect] = useState('');
  const user = useSelector(state => state?.value);
  const [selectedCloudOption, setSelectedCloudOption] = useState('Amazon Web Services');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isWork, onOpen: isWorkOpen, onClose: isWorkClose } = useDisclosure();


  // if(user === undefined || user == null){
  //   navigation('/auth');
  // }

  const onNext = () => {
    onClose();
    isWorkOpen();
  }

  useEffect(() => { console.log(selectedCloudOption) }, [selectedCloudOption])


  return (
    <SideWrapper>
      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"22px"} mt={"20px"} fontWeight={"600"}>Choose a workflow type or select a template</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={"30px 30px"} marginBottom={"0"}>
            <Stack direction={"row"} gap={"30px"} justifyContent={"center"}>
              <Stack flex={1}>
                <Box display={"flex"} gap={"10px"} onClick={() => { setSelect("Blank Custom Workflow") }} cursor={"pointer"} width={"290px"} height={"48px"} padding={"16px"} justifyContent={"space-between"} alignItems={"center"} bg={select == 'Blank Custom Workflow' ? "#D3E3FD" : "#fff"} border={"1px solid #D0D5DD"} borderRadius={"4px"} >
                  <Text>Blank Custom Workflow</Text>
                  <GrDocument fontSize={"18px"} />
                </Box>
                <Box display={"flex"} gap={"10px"} onClick={() => { setSelect("Incident Automation Rule") }} cursor={"pointer"} width={"290px"} height={"48px"} padding={"16px"} justifyContent={"space-between"} alignItems={"center"} bg={select == 'Incident Automation Rule' ? "#D3E3FD" : "#fff"} border={"1px solid #D0D5DD"} borderRadius={"4px"} >
                  <Text>Incident Automation Rule</Text>
                  <GrDocument fontSize={"18px"} />
                </Box>
                <Box display={"flex"} gap={"10px"} onClick={() => { setSelect("Pivot Menu Workflow") }} cursor={"pointer"} width={"290px"} height={"48px"} padding={"16px"} justifyContent={"space-between"} alignItems={"center"} bg={select == 'Pivot Menu Workflow' ? "#D3E3FD" : "#fff"} border={"1px solid #D0D5DD"} borderRadius={"4px"} >
                  <Text>Pivot Menu Workflow</Text>
                  <GrDocument fontSize={"18px"} />
                </Box>
              </Stack>
              <Stack flex={"1"}>
                <Box width={"2px"} height={"150px"} backgroundColor={"#D0D5DD"}> </Box>
              </Stack>
              <Stack flex={1}>
                <Text fontSize={"20px"} fontWeight={"600"}>Pivot Menu Workflow</Text>
                <Text fontSize={"14px"} fontWeight={"600"}>Workflow details</Text>
                <Text color={"#8A959E"} fontSize={"11px"} mt={"30px"} mb="40px" fontWeight={"400"}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                </Text>
                <Stack padding={"40px 0"} direction={"row"}>
                  <Button color="white" onClick={onNext} padding={"12px 60px"} backgroundColor={"#0B57D0"}>Continue</Button>
                  <Button onClick={onClose} padding={"12px 60px"}>Cancel</Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"lg"} isOpen={isWork} onClose={isWorkClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"22px"} mt={"5px"} fontWeight={"600"}>Import Workflow</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={"30px"} marginBottom={"0"}>
            <Text fontSize={"18px"} fontWeight={"500"}>Import From</Text>
            <Tabs>
              <TabList mt={"10px"} fontSize={"14px"} fontWeight={"600"} width={"30%"}>
                <Tab>Git</Tab>
                <Tab>Browse</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box >
                    <Text fontSize={"11px"} color={"#8A959E"} fontWeight={"400"}>Git Repository*</Text>
                    <Select color={"#8A959E"} m={"5px 0"} width={"100%"}>
                      <option style={{ display: "none" }}>Select</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </Select>
                  </Box>
                  <Stack alignItems={"center"} marginTop="10px" marginBottom={"15px"} direction={"row"} gap={"8px"}>
                    <AiOutlineQuestionCircle color='#8A959E' />
                    <Text fontWeight={"400"} color={"#8A959E"} fontSize={"11px"}>Learn more about Cisco-provided GitHub repositories </Text>
                  </Stack>
                  <Box m="5px 0">
                    <Text fontSize={"11px"} color={"#8A959E"} fontWeight={"400"}>Filename</Text>
                    <Select color={"#8A959E"} m={"5px 0"} width={"100%"}>
                      <option style={{ display: "none" }}>Select</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </Select>
                  </Box>
                  <Box m="5px 0">
                    <Text fontSize={"11px"} color={"#8A959E"} fontWeight={"400"}>Git Version</Text>
                    <Select color={"#8A959E"} m={"5px 0"} width={"100%"}>
                      <option style={{ display: "none" }}>Select</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </Select>
                  </Box>
                  <Box>
                    <Checkbox fontSize={"20px"}>Import as a new workflow (duplicate)</Checkbox>
                    <Stack alignItems={"center"} m={"10px 0"} marginTop="10px" marginBottom={"15px"} direction={"row"} gap={"8px"}>
                      <AiOutlineQuestionCircle color='#8A959E' />
                      <Text fontWeight={"400"} color={"#8A959E"} fontSize={"11px"}>Learn more about Cisco-provided GitHub repositories </Text>
                    </Stack>
                  </Box>
                  <Stack padding={"40px 0"} justifyContent={"right"} direction={"row"}>
                    <Button color="white" onClick={onNext} padding={"14px 30px"} backgroundColor={"#0B57D0"}>Import</Button>
                    <Button onClick={isWorkClose} padding={"14px 30px"}>Cancel</Button>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Text fontSize={"11px"} fontWeight={"400"} color={"#8A959E"}>Paste JSON or upload the workflow to import*</Text>
                  <Textarea fontSize={"17px"} minH={"96px"} overflowY={"auto"}>
                  </Textarea>
                  <Button bg={"#D3E3FD"} mt={"10px"} borderRadius={"10px"} padding={"10px 20px"} color={"black"}>Browse</Button>
                  <Box mt={"14px"}>
                    <Checkbox fontSize={"20px"}>Import as a new workflow (duplicate)</Checkbox>
                    <Stack alignItems={"center"} m={"10px 0"} marginTop="10px" marginBottom={"15px"} direction={"row"} gap={"8px"}>
                      <AiOutlineQuestionCircle color='#8A959E' />
                      <Text fontWeight={"400"} color={"#8A959E"} fontSize={"11px"}>Learn more about Cisco-provided GitHub repositories </Text>
                    </Stack>
                  </Box>
                  <Stack padding={"40px 0"} justifyContent={"right"} direction={"row"}>
                    <Button color="white" onClick={onNext} padding={"14px 30px"} backgroundColor={"#0B57D0"}>Import</Button>
                    <Button onClick={onClose} padding={"14px 30px"}>Cancel</Button>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        <LocationHeader followingRoute={[selectedCloudOption]} />
        <NotificationIcon />
        {/* Cloud options */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'10px'}
          spacing={0}
          // padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
        >
          {solutionBuilderdata.map((v, i) => (
            <CloudOptionButton
              {...v}
              key={i}
              selectedCloudOption={selectedCloudOption}
              setSelectedCloudOption={setSelectedCloudOption}
            />
          ))}
        </Stack>
        {
          selectedCloudOption === 'Add Device/Rule' ?
            <ZeroTrustPosture />
            :
            <SpacedStack
              direction={'row'}
              alignItems={'center'}
              gap={'10px'}
              flexWrap={'wrap'}
              spacing={0}
            >
              <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                <Text fontSize={"22px"} fontWeight={"600"}>Workflows</Text>
                <Button backgroundColor="primary.100" onClick={onOpen} _hover={"none"} borderRadius="10px" color='white'>Create Workflow</Button>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                <Box>
                  <Text fontSize={"16px"} mb={"10px"} fontWeight={"500"}>Search</Text>
                  <InputGroup >
                    <InputRightElement>
                      <AiOutlineSearch fontSize={"20px"} />
                    </InputRightElement>

                    <Input width="310px" placeholder='Search' type='Search' />
                  </InputGroup >
                </Box>
                <Box>
                  <Text fontSize={"16px"} mb={"10px"} fontWeight={"500"}>Ready State</Text>
                  <Select width={"310px"}>
                    <option style={{ display: "none" }}>Select</option>
                    <option>option1</option>
                    <option>option2</option>
                    <option>option3</option>
                  </Select>
                </Box>
                <Box>
                  <Text fontSize={"16px"} mb={"10px"} fontWeight={"500"}>Category</Text>
                  <Select width={"310px"}>
                    <option style={{ display: "none" }}>Select</option>
                    <option>option1</option>
                    <option>option2</option>
                    <option>option3</option>
                  </Select>
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} alignItems={"end!important  "}>
                  <Button backgroundColor={"transparent"}>Reset</Button>
                </Box>
              </Stack>
              <solutionCard />
            </SpacedStack>
        }
        {
          selectedCloudOption !== 'Add Device/Rule' &&
          <SpacedStack>
            <Stack direction={"row"} gap={"20px"} flexWrap={"wrap"}>
              <SolutionCard />
              <SolutionCard />
              <SolutionCard />
              <SolutionCard />
              <SolutionCard />
              <SolutionCard />
            </Stack>
          </SpacedStack>
        }
      </Stack>
    </SideWrapper>
  );

}
