import { Box, Heading, Spinner, Stack, useToast } from '@chakra-ui/react';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line, Pie, Scatter } from 'react-chartjs-2';
import { GET } from '../utilities/ApiProvider.js';
import { BaseURL } from '../utilities/config.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  barThickness: 20,
  plugins: {
    legend: {
      position: false
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black', // Color of the data labels
      anchor: 'end', // Position of the data labels (e.g., 'end', 'start', 'center')
      align: 'top', // Alignment of the data labels (e.g., 'top', 'bottom', 'middle')
      font: {
        weight: 'bold'
      },
      formatter: (value) => ['12', '24', '12', '41']
    }
  }
};

const labels3 = ['Prompts', 'Voice', 'PA', 'Co-Pilot'];
export const data3 = {
  labels: labels3,
  datasets: [
    {
      data: [20, 30, 30, 40],
      backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
    },
    {
      data: [40, 50, 50, 60],
      backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
    },
    {
      data: [60, 70, 70, 80],
      backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
    },
  ],
};

const labels4 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
export const data4 = {
  labels: labels4,
  datasets: [
    {
      data: [20, 30, 30, 40],
      backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
    },
    {
      data: [60, 70, 70, 80],
      backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
    },
  ],
};

const labels5 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
export const data5 = {
  labels: labels5,
  datasets: [
    {
      data: [20, 30, 30, 40],
      backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
    },
    {
      data: [60, 70, 70, 80],
      backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
    },
  ],
};

const labels6 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
export const data6 = {
  labels: labels6,
  datasets: [
    {
      data: [20, 30, 30, 40],
      backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
    },
    {
      data: [60, 70, 70, 80],
      backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
    },
  ],
};

const labels7 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
export const data7 = {
  labels: labels7,
  datasets: [
    {
      data: [70, 90, 80, 30],
      backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
    }
  ],
};

const labels8 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
export const data8 = {
  labels: labels8,
  datasets: [
    {
      data: [60, 80, 70, 20],
      backgroundColor: ['#726681', '#9C9C9C', '#8B3805', '#4467BF'],
    }
  ],
};

const labels9 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
export const data9 = {
  labels: labels9,
  datasets: [
    {
      data: [40, 30, 20, 40],
      backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
    }
  ],
};

const labels10 = ['Validation', 'Met-Data', 'Linear', 'Privacy', 'Integrity', 'Encryption', 'Secure Access', 'Model Theft', 'Data Poisoning', 'Resilience'];
export const data10 = {
  labels: labels10,
  datasets: [
    {
      data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
    },
    {
      data: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60],
      backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
    },
    {
      data: [8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
      backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
    },
    {
      data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      backgroundColor: ['#1F9232', '#1F9232', '#1F9232', '#1F9232'],
    },
  ],
};

const EnterpriseAiData = () => {

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [chartsData, setChartsData] = useState(null);
  const [firstChart, setFirstChart] = useState({
    labels: ['After Sanitization', 'Before Sanitization'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#4467BF', '#FB9522'],
      },
    ],
  });
  const [secondChart, setSecondChart] = useState({
    labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
    ],
  });
  const [thirdChart, setThirdChart] = useState({
    labels: ['Bias', 'No Refusal', 'Relevance'],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
    ],
  });
  const [fourthChart, setFourthChart] = useState({
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
    ],
  });
  const [fifthChart, setFifthChart] = useState({
    labels: ['Non-toxic'],
    datasets: [
      {
        data: [0],
        backgroundColor: ['#4467BF'],
      },
    ],
  });
  const [sixthChart, setSixthChart] = useState({
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      }
    ],
  });
  const [seventhChart, setSeventhChart] = useState({
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
      {
        data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
    ],
  });
  const [eighthChart, setEighthChart] = useState({
    labels: ['Address', 'Email', 'None'],
    datasets: [
      {
        data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
        backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
      },
    ],
  });

  const getChartData = async () => {
    setIsLoading(true);
    try {
      let res = await GET(`api/v1/llmops/graphs`)
      console.log(res)
      setChartsData(res)
    } catch (err) {
      toast({
        description: err.message,
        duration: 4000,
        isClosable: true,
        position: 'top-right',
        status: 'false'
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getChartData()
    return () => { }
  }, [])

  useEffect(() => {
    if (chartsData !== null) {
      setFirstChart({
        labels: ['After Sanitization', 'Before Sanitization'],
        datasets: [
          {
            data: [chartsData?.first_graph['After Sanitization'], chartsData?.first_graph['Before Sanitization']],
            backgroundColor: ['#4467BF', '#FB9522'],
          },
        ],
      });
      setSecondChart({
        labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
        datasets: [
          {
            data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
            backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
          },
        ],
      });
      setThirdChart({
        labels: ['Bias', 'No Refusal', 'Relevance'],
        datasets: [
          {
            data: [chartsData?.third_graph?.fail_series['Bias'], chartsData?.third_graph?.fail_series['NoRefusal'], chartsData?.third_graph?.fail_series['Relevance']],
            backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
          },
        ],
      });
      setFourthChart({
        labels: Object.keys(chartsData?.fourth_graph?.stats_prompt_character_count),
        datasets: [
          {
            data: Object.values(chartsData?.fourth_graph?.stats_prompt_character_count),
            backgroundColor: '#4467BF',
            borderColor: '#4467BF',
          },
          {
            data: Object.values(chartsData?.fourth_graph?.stats_response_character_count),
            backgroundColor: '#FB9522',
            borderColor: '#FB9522',
          }
        ],
      });
      setFifthChart({
        labels: ['Non-toxic'],
        datasets: [
          {
            data: [chartsData?.fifth_graph?.toxicity_label_counts['non-toxic']],
            backgroundColor: ['#4467BF'],
          },
        ],
      });
      setSixthChart({
        labels: Object.keys(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
        datasets: [
          {
            data: Object.values(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
            backgroundColor: '#4467BF',
            borderColor: '#4467BF',
          }
        ],
      });
      setSeventhChart({
        labels: Object.keys(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
        datasets: [
          {
            data: Object.values(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
            backgroundColor: '#4467BF',
            borderColor: '#4467BF',
          },
          {
            data: Object.values(chartsData?.seventh_garph?.stats_response_sentiment_nltk),
            backgroundColor: '#FB9522',
            borderColor: '#FB9522',
          }
        ],
      });
      setEighthChart({
        labels: ['Address', 'Email', 'None'],
        datasets: [
          {
            data: [chartsData?.eighth_graph?.has_patterns_counts['address'], chartsData?.eighth_graph?.has_patterns_counts['email'], chartsData?.eighth_graph?.has_patterns_counts['none']],
            backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
          },
        ],
      });
    }
    return () => { }
  }, [chartsData]);

  return (

    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack
          margin={'0 0 30px 0'}
          direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <LocationHeader
            forcedText={'Enterprise AI, Data, and LLMOps'}
          />
          <NotificationIcon />
        </Stack>
        <Stack padding={'35px 25px'} borderRadius={'12px'} bgColor={'#fff'}>
          {
            isLoading ? <Spinner />
              :
              <Stack spacing={0} w="100%" h="100%" border={'1px solid #adadad'}>
                <Stack direction={'row'} spacing={0}>
                  <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Heading fontSize={"19px"} textAlign="center" >Enterprise AI Utilization</Heading>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>AI/LLM Agents</Heading>
                    <Box p="15px">
                      <Bar options={options} data={firstChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Business Function</Heading>
                    <Box p="15px">
                      <Bar options={options} data={secondChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Risk</Heading>
                    <Box p="15px">
                      <Bar options={options} data={thirdChart} />
                    </Box>
                  </Box>
                </Stack>
                <Stack direction={'row'} spacing={0}>
                  <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
                    <Heading fontSize={"19px"} textAlign="center">Input Validation</Heading>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Bias, Toxicity, Relevance, <br /> Sensitivity</Heading>
                    <Box p="5px">
                      {/* <Bar options={options} data={data4} /> */}
                      <Scatter options={options} data={fourthChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Hallucination, Ban Topic,<br /> Anonymize, HITL</Heading>
                    <Box p="15px" height="200px" display="flex" justifyContent="center">
                      <Pie options={options} data={fifthChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Secrets, Prompt Injection, Invisible <br /> Text, Accuracy</Heading>
                    <Box p="15px">
                      <Bar options={options} data={sixthChart} />
                    </Box>
                  </Box>
                </Stack>
                <Stack direction={'row'} spacing={0}>
                  <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
                    <Heading fontSize={"19px"} textAlign="center">Output Validation</Heading>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
                    <Box p="15px">
                      <Scatter options={options} data={seventhChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
                    <Box p="15px" height={'206px'} display="flex" justifyContent="center">
                      <Pie options={options} data={eighthChart} />
                    </Box>
                  </Box>
                  <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
                    <Box p="15px">
                      <Bar options={options} data={data9} />
                    </Box>
                  </Box>
                </Stack>
                <Stack direction={'row'} spacing={0}>
                  <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
                    <Heading fontSize={"19px"} textAlign="center">DataOps</Heading>
                  </Box>
                  <Box w="81%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
                    <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
                    <Box p="15px">
                      <Bar height={"60px"} options={options} data={data10} />
                    </Box>
                  </Box>
                </Stack>
              </Stack>
          }
        </Stack>
      </Stack>
    </SideWrapper >
  );
};

export default EnterpriseAiData;
