import { Box, Button, Icon, Stack, Text } from '@chakra-ui/react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { BiReset } from 'react-icons/bi'

export default function CloudOptionButton({ icon, name, selectedCloudOption, setSelectedCloudOption, setHostOptionsState, resetHostOptionsState }) {
    // console.log(icon, 'icon ere')
    return (
        <>
            <Button
                w={{ base: "100%", md: "50%", lg: "30%", xl: "22%" }}
                background={'#fff'}
                border={'2px solid #fff'}
                borderBottomColor={name === selectedCloudOption ? '#36454f' : '#fff'}
                borderRadius={0}
                padding={'32px 17px'}
                _hover={{ bgColor: '#fff' }}
                _active={{ bgColor: '#fff' }}
                onClick={() => {
                    setSelectedCloudOption(name)
                }
                }
            >
                <Stack
                    w={'full'}
                    direction={'row'}
                    alignItems={'center'}
                    gap={'10px'}
                    justifyContent={'space-between'}
                >
                    <Box
                        display={'flex'}
                        gap={'10px'}
                        alignItems={'center'}
                    >
                        <Icon
                            color={name === selectedCloudOption ? '#36454f' : '#c3c9d2'}
                            fontSize={'18px'}
                            as={icon ?? ''}
                        />
                        <Text
                            fontSize={'16px'}
                            fontWeight={'600'}
                            color={name === selectedCloudOption ? '#36454f' : '#c3c9d2'}
                        >
                            {name}
                        </Text>
                    </Box>
                    {/* <Icon fontWeight={'700'} fontSize={'17px'} as={name === selectedCloudOption ? BsChevronUp : BsChevronDown} /> */}
                </Stack>
            </Button>
        </>
    )
}
