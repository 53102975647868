import Logo from "../../assets/images/logoB.png";
import { Typography, Input, Form, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AiOutlineIdcard, AiOutlineLock } from 'react-icons/ai';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { loadUser, pushNotificationState } from '../../reducers/useReducers';
import { useDispatch } from 'react-redux';
import useReducers from '../../reducers/useReducers';
import { useForm, Controller } from 'react-hook-form';
import { GET, POST, initPushNotification, pushNotificationContext } from '../../utilities/ApiProvider';
import { BaseURL } from '../../utilities/config';
import axios from 'axios';
import { Link as ReactLink } from 'react-router-dom';
import NoSslModal from '../Modals/NoSslModal';

export default function Login({ setShowLogin, isLoading, setIsLoading }) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toast = useToast();
  const navigation = useNavigate();
  const [localUserInfo, setLocalUserInfo] = useState(null);
  const [userName, setUserName] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [localOrgIdHere, setLocalOrgIdHere] = useState(0);
  let LocalOrgId;

  useEffect(() => {
    (async () => {
      let localUser = await localStorage.getItem('en4cibleUser');
      setLocalUserInfo(JSON.parse(localUser));
    })();
  }, []);

  useEffect(() => {
    if (localUserInfo) {
      (async () => {
        setIsLoading(true);
        let data = {
          orgId: localOrgIdHere ?? 22,
          username: localUserInfo?.username,
          refreshToken: localUserInfo?.refreshToken
        };
        try {
          let response = await POST(`api/onboard/users/refresh`, data, {
            headers: {
              "Cache-Control": "no-cache",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
          );
          // console.log(response)
          if (response?.username === localUserInfo?.username) {
            localStorage.setItem('en4cibleUser', JSON.stringify(response));
            navigate('/cloud-onboarding')
          } else if (response?.error) {
            toast({
              description: 'User expired, Please provide valid credentials',
              isClosable: true,
              status: 'error',
              position: 'top-right',
              duration: 4500,
            });
            localStorage.removeItem('en4cibleUser')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            setLocalUserInfo(null)
          } else {
            onOpen();
          }
        } catch (error) {
          toast({
            description: error?.message,
            isClosable: true,
            status: 'error',
            position: 'top-right',
            duration: 4500,
          });
          setIsLoading(false);
        }
        setIsLoading(false);
      })()
    }
  }, [localUserInfo]);

  useEffect(() => {
    (async () => {
      LocalOrgId = await localStorage.getItem('orgId');
      console.log(LocalOrgId)
      setLocalOrgIdHere(LocalOrgId);
    })();
  }, []);


  const submitLoginHandler = async (data) => {
    if (localOrgIdHere === null) {
      let fetchedOrgId = await GET(`api/onboard/users/find-basic/${data?.username}`);
      if (fetchedOrgId?.orgId) {
        dataSubmitHandler(fetchedOrgId?.orgId, data)
      } else {
        toast({
          description: fetchedOrgId?.error,
          isClosable: true,
          status: 'error',
          position: 'top-right',
          duration: 4500,
        });
      }
    } else {
      dataSubmitHandler(localOrgIdHere, data);
    }
  }

  const dataSubmitHandler = async (orgId, data) => {
    setIsLoading(true)
    let response = await POST(`api/onboard/users/authenticate`, {
      username: data.username,
      passcode: data.passcode,
      orgId: orgId,
      totp: data.totp
    });
    // console.log(response)
    if (response?.accessToken) {
      toast({
        description: `User logged in as: ${response.orgName}`,
        isClosable: true,
        status: 'success',
        position: 'top-right',
        duration: 4500,
      });
      localStorage.setItem('orgId', orgId);
      localStorage.setItem('accessToken', response?.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      setShowLogin(true);
      navigation('/cloud-onboarding');
      dispatch(loadUser(response));

      var user = JSON.parse(localStorage.getItem("en4cibleUser"))
      var connection = initPushNotification(user.username, function (notificationMessage) {
        toast({
          position: 'top-right',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: notificationMessage,
        })
      });

      //Storing connection ref globally;
      dispatch(pushNotificationState(connection))

    } else if (response?.error) {
      toast({
        description: response?.error,
        isClosable: true,
        status: 'error',
        position: 'top-right',
        duration: 4500,
      });
    }
    setIsLoading(false)
  }

  // const submitLogin = async data => {
  //   setIsLoading(true);
  //   let tepdata;
  //   if (data.username === "seqoria2@testtest.com") {
  //     tepdata = {
  //       passcode: data.passcode,
  //       username: data.username,
  //       orgId: LocalOrgId ?? 22,
  //       // totp: data.totp
  //     };
  //   } else {
  //     tepdata = {
  //       passcode: data.passcode,
  //       username: data.username,
  //       orgId: LocalOrgId ?? 22,
  //       totp: data.totp
  //     };
  //   }
  //   try {
  //     let res = await POST(`api/onboard/users/find-basic/${data?.username}`, {
  //       headers: {
  //         "Cache-Control": "no-cache",
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     }
  //     );
  //     if (res?.orgId) {
  //       let response = await POST(`api/onboard/users/authenticate`, {
  //         ...tepdata,
  //         orgId: res?.orgId
  //       }, {
  //         headers: {
  //           "Cache-Control": "no-cache",
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //       );
  //       // console.log(response)
  //       if (response?.accessToken) {
  //         toast({
  //           description: `User logged in as: ${response.orgName}`,
  //           isClosable: true,
  //           status: 'success',
  //           position: 'top-right',
  //           duration: 4500,
  //         });
  //         localStorage.setItem('orgId', tepdata?.orgId);
  //         localStorage.setItem('accessToken', response?.accessToken);
  //         localStorage.setItem('refreshToken', response.refreshToken);
  //         setShowLogin(true);
  //         navigation('/cloud-onboarding');
  //         dispatch(loadUser(response));
  //       } else if (response?.error) {
  //         toast({
  //           description: response?.error,
  //           isClosable: true,
  //           status: 'error',
  //           position: 'top-right',
  //           duration: 4500,
  //         });
  //       }
  //     } else {
  //       onOpen();
  //     }
  //   } catch (error) {
  //     toast({
  //       description: error?.message,
  //       isClosable: true,
  //       status: 'error',
  //       position: 'top-right',
  //       duration: 4500,
  //     });
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  // };

  return (
    <>
      <NoSslModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      <Image src={Logo} width={'160px'} margin={'65px auto 100px'} />
      <Heading color={'primary.100'} fontSize={'30px'} fontWeight={600} textAlign={'center'}>
        Login your account
      </Heading>
      <Text
        fontSize={'16px'}
        margin={'5px 0 0 0'}
        color={'#8A959E'}
        textAlign={'center'}
      >
        Please login with your username and password details
      </Text>
      <Divider style={{ margin: '35px 0' }} />
      <form
        onSubmit={handleSubmit(e => {
          submitLoginHandler(e);
        })}
      >
        <Stack w={'full'} mb={4}>
          <Typography.Text>Login ID</Typography.Text>
          <Form.Item>
            <Controller
              name="username"
              control={control}
              rules={{ required: 'This is Required' }}
              render={({ field }) => (
                <Input
                  onChange={e => setUserName(e.target.value)}
                  {...field}
                  size="large"
                  placeholder="Allen"
                  name="username"
                  prefix={<UserOutlined />}
                //   onChange={e =>
                //     setLoginData({
                //       ...loginData,
                //       username: e.target.value,
                //     })
                //   }
                />
              )}
            />

            <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
              {errors.username?.message}
            </Text>
          </Form.Item>
        </Stack>
        <Stack w={'full'} mb={4}>
          <Typography.Text>Password</Typography.Text>
          <Form.Item>
            <Controller
              name="passcode"
              control={control}
              rules={{ required: 'This is Required' }}
              render={({ field }) => (
                <Input
                  type={showPass ? 'text' : 'password'}
                  size="large"
                  {...field}
                  name="passcode"
                  placeholder="**************"
                  prefix={<AiOutlineLock />}
                //   onChange={e =>
                //     setLoginData({
                //       ...loginData,
                //       passcode: e.target.value,
                //     })
                //   }
                />
              )}
            />

            <Button
              onClick={() => setShowPass(!showPass)}
              position={'absolute'}
              right={'5px'}
              background={'transparent'}
              _hover={{ bgColor: 'transparent' }}
              _active={{ bgColor: 'transparent' }}
              zIndex={10}
            >
              <Icon as={showPass ? BsEyeFill : BsEyeSlashFill} />
            </Button>

            <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
              {errors.passcode?.message}
            </Text>
          </Form.Item>
        </Stack>
        {
          localUserInfo === null &&
          <Stack w={'full'} mb={4}>
            <Typography.Text>TOTP:</Typography.Text>
            <Form.Item>
              <Controller
                name="totp"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    type={showPass ? 'text' : 'passcode'}
                    size="large"
                    {...field}
                    name="totp"
                    placeholder="**************"
                    prefix={<AiOutlineLock />}
                  //   onChange={e =>
                  //     setLoginData({
                  //       ...loginData,
                  //       passcode: e.target.value,
                  //     })
                  //   }
                  />
                )}
              />

              <Button
                onClick={() => setShowPass(!showPass)}
                position={'absolute'}
                right={'5px'}
                background={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
                zIndex={10}
              >
                <Icon as={showPass ? BsEyeFill : BsEyeSlashFill} />
              </Button>

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.passcode?.message}
              </Text>
            </Form.Item>
          </Stack>
        }
        <Stack textAlign={'right'} direction={'row'} justifyContent={'flex-end'}>
          <Link w={'fit-content'} fontSize={'14px'} textDecoration={'none !important'} as={ReactLink} to={'#'} >Forgot Password</Link>
        </Stack>
        <Button
          // onClick={() => { submitLoginHandler() }}
          isLoading={isLoading}
          loadingText="Verifying"
          type="submit"
          bgColor={'primary.100'}
          color={'#fff'}
          padding={'12px'}
          w={'full'}
          margin={'25px 0 25px'}
          fontSize={'16px'}
          fontWeight={'500'}
          border={'2px solid primary.100'}
          _hover={{
            bgColor: 'transparent',
            color: '#4A4A4A',
          }}
        >
          Sign In
        </Button>
      </form>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Typography.Text color="#262626">
          Don't have an account?{' '}
        </Typography.Text>
        <Typography.Text
          style={{
            textDecoration: 'underline',
            color: 'primary.100',
            fontWeight: '600',
            cursor: 'pointer',
          }}
          onClick={() => setShowLogin(false)}
        >
          Register Now
        </Typography.Text>
      </Stack>
    </>
  );
}
