import d1 from '../assets/images/desired-icons/d1.svg'
import d2 from '../assets/images/desired-icons/d2.svg'
import d3 from '../assets/images/desired-icons/d3.svg'
import d4 from '../assets/images/desired-icons/d4.svg'
import d5 from '../assets/images/desired-icons/d5.svg'
import d6 from '../assets/images/desired-icons/d6.svg'
import d7 from '../assets/images/desired-icons/d7.svg'
import d8 from '../assets/images/desired-icons/d8.svg'
import d9 from '../assets/images/desired-icons/d9.svg'
import d10 from '../assets/images/desired-icons/gen1.svg'
import d11 from '../assets/images/desired-icons/gen2.svg'
import d12 from '../assets/images/desired-icons/dos.svg'
import d13 from '../assets/images/desired-icons/lens.svg'
import d14 from '../assets/images/desired-icons/incident.svg'
import d15 from '../assets/images/desired-icons/supply.svg'
import d16 from '../assets/images/desired-icons/cyber.svg'
import d17 from '../assets/images/desired-icons/regular.svg'
import d18 from '../assets/images/desired-icons/cloud.svg'
import data from '../assets/images/desired-icons/BlueIcons/Frame 38463.png'
import responsible from '../assets/images/desired-icons/BlueIcons/Asset 2 1.png'
import adverse from '../assets/images/desired-icons/BlueIcons/Asset 1 2.png'
import ml from '../assets/images/desired-icons/BlueIcons/Asset 2 2.png'
import supply from '../assets/images/desired-icons/BlueIcons/Asset 3 2.png'
import incident from '../assets/images/desired-icons/BlueIcons/Asset 5 1.png'
import control from '../assets/images/desired-icons/BlueIcons/control-panel 1.png'

const desiredOptions = [
    { id: 1, icon: d10, name: 'Collect & Prepare Data', },
    { id: 2, icon: d11, name: 'Model Design & Development', },
    // { id: 3, icon: d12, name: 'Model Training, Fine Tuning, & Evaluation', },
    { id: 4, icon: d13, name: 'Model Deployment, Monitoring, & Management', },
    // { id: 5, icon: d14, name: 'Inference & Prompt Engineering', },
    { id: 6, icon: d16, name: 'Infrastructure and Operations', },
    // { id: 7, icon: d15, name: 'Supply-Chain, Third-Party Risk Management', },
    // { id: 8, icon: d1, name: 'Cyber Resiliency', },
    // { id: 9, icon: d17, name: 'Regulatory Compliance (NIST, ISO, GDPR, HIPAA, PCI, etc.)', },
    // { id: 10, icon: d9, name: 'Enterprise Risk Tolerance', },
    // { id: 11, icon: d18, name: 'Distributed Cloud Security', },
    // { id: 12, icon: d8, name: 'Vulnerability Management', },
    // { id: 13, icon: d2, name: 'Zero-Trust Posture - Multicloud', },
    // { id: 14, icon: d3, name: 'Skills Gap Assessment - GenAI, Cloud, Security', },
    // { id: 15, icon: d4, name: 'Enhance Control Coverage', },
]

const genPolicySubCatData = [
    { id: 1, icon: d10, name: 'Enhanced Security & Risk Management', },
    { id: 2, icon: d11, name: 'Threat Detection & Incident Response ', },
    { id: 3, icon: d12, name: 'Data Protection & Governance', },
    { id: 4, icon: d13, name: 'Supply-Chain & Third-Party Risk Management', },
    { id: 5, icon: d14, name: 'Operational Resilience &  Continuity', },
    { id: 6, icon: d16, name: 'Ethical AI & Trustworthiness', },
    { id: 7, icon: d16, name: 'Regulatory Compliance & Reporting', },
    { id: 8, icon: d16, name: 'Enhance Controls Coverage', },
]

const genPolicyData = [
    { id: 1, icon: d10, name: 'Enhanced Security & Risk Management', },
    { id: 2, icon: d11, name: 'Threat Detection & Incident Response ', },
    { id: 3, icon: d12, name: 'Data Protection & Governance', },
    { id: 4, icon: d13, name: 'Supply-Chain & Third-Party Risk Management', },
    { id: 5, icon: d14, name: 'Operational Resilience &  Continuity', },
    { id: 6, icon: d16, name: 'Ethical AI & Trustworthiness', },
    { id: 7, icon: d16, name: 'Regulatory Compliance & Reporting', },
    { id: 8, icon: d16, name: 'Enhance Controls Coverage', },
]

const desiredNewButton = [
    { id: 1, icon: d10, activeIcon: data, name: 'Data Integrity, Privacy', },
    { id: 2, icon: d11, activeIcon: responsible, name: 'Responsible and Ethical AI Data risks', },
    { id: 3, icon: d12, activeIcon: adverse, name: 'AI Adversarial Threat Detection', },
    { id: 4, icon: d13, activeIcon: ml, name: 'ML/Data Ops Risks Management', },
    { id: 5, icon: d14, activeIcon: supply, name: 'AI Supply Chain and Third-Party Risks Management', },
    { id: 6, icon: d16, activeIcon: incident, name: 'AI Incident Response and Disaster Recovery', },
    { id: 7, icon: d16, activeIcon: incident, name: 'Enhance Controls Coverage', },
    { id: 8, icon: d16, activeIcon: control, name: 'Regulatory Compliance and Governance', },
]

const desiredExecutors = [
    { name: 'Account Information (Rest API)' },
    { name: 'Get VMs Information (CLI)' },
    { name: 'CLI' },
    { name: 'Rest API' },
]

const desiredCompliance = [
    { name: 'S3 Access Vulnerability' },
    { name: 'Account MFA Enabled' },
    { name: 'Joined Host Before Login' },
]

const neighborConfigOptions = [
    { id: 1, icon: d1, name: 'Zone 0', },
    { id: 2, icon: d2, name: 'Zone 1', },
    { id: 3, icon: d3, name: 'Zone 2', },
    { id: 4, icon: d4, name: 'Zone 3', },
]
const subCatList = {
    // {id: 1, icon: d10, name: 'Collect & Prepare Data', children: ['Access Control Classification', 'Label flipping Data Poisoning', 'Leakage', 'Exfilteration Data Lineage', '']},
    'Collect & Prepare Data': {
        id: 2, icon: d11, name: 'Collect & Prepare Data', children: [
            'Access Control',
            'Data Poisoning, Leakage, Exfilteration',
            'Data Lineage',
            'Data Integrity & Privacy',
            'Adversarial Attacks',
            'Regulatory Compliance and Governance']
    },
    'Model Design & Development': {
        id: 2, icon: d11, name: 'Model Design & Development', children: [
            'Classification, Label flipping',
            'Model Training/Evaluation Poisoning',
            'Backdoor Machine Learning/Trojan Model',
            'Bias and Fairness',
            'Model Supply-Chain Risk',
            'Adversarial Attacks',
            'Feature Manipulation',
        ]
    },
    // 'Model Training, Fine Tuning, & Evaluation': { id: 4, icon: d13, name: 'Model Training, Fine Tuning, & Evaluation', children: ['Model Training/Evaluation Poisoning', 'Backdoor Machine Learning/Trojan Model', 'Bias and Fairness', 'Model Supply-Chain Risk', 'Model Inversion', 'Access Control', 'Audit and monitoring', 'Regulatory Compliance and Governance'] },
    'Model Deployment, Monitoring, & Management': {
        id: 5, icon: d14, name: 'Model Deployment, Monitoring, & Management', children: [
            'Prompt Injection, Output Validation',
            'Model Theft, Evasion,and Extraction',
            'Denial of Service (DoS) Attacks',
            'Privacy Risks',
            'Model Inference Attacks',
            'Model Inversion Attacks',
            'Model Drift, Bias, and Fairness',
            'Regulatory Compliance and Governance']
    },
    'Infrastructure and Operations': {
        id: 6, icon: d16, name: 'Infrastructure and Operations', children: [
            'Infrastructure Vulnerabilities',
            'Cloud and GenAI systems Resiliency',
            'Third-Party Risks',
            'Access Control',
            'Security Posture',
            'Regulatory Compliance and Governance']
    },
    // 'Inference & Prompt Engineering': { id: 3, icon: d12, name: 'Inference & Prompt Engineering', children: ['Access Control', 'Classification, Label flipping', 'Data Poisoning, Leakage, Exfilteration', 'Data Lineage', 'Data Integrity & Privacy', 'Adversarial Attacks', 'Feature manipulation', 'Audit and monitoring', 'Regulatory Compliance and Governance'] },
}

export { desiredOptions, desiredExecutors, desiredCompliance, genPolicyData, subCatList, desiredNewButton };