import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useState } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

const labels = [
  'Training Data',
  'AI Pipeline',
  'Harmful Data Input/Output',
  'Data Access, Leakage',
  'Data Poisoning',
  'Feedback Loop Efficacy',
  'Lineage/Provenance',
];

const dataSet = [35, 21, 19, 14, 9, 8, 3]

export const data = {
  labels,
  datasets: [
    {
      label: '# of Votes',
      data: dataSet,
      backgroundColor: dataSet.map((percent) => colorSelector(percent)),
      borderColor: dataSet.map((percent) => colorSelector(percent)),
      borderWidth: 1,
      hoverOffset: 4,
    },
  ],
};

const AISummary = () => {
  const [hoveredLabel, setHoveredLabel] = useState(null);
  const [hoveredPercentage, setHoveredPercentage] = useState(null);

  const options = {
    plugins: {
      legend: {
        display: false, // Disable the default legend
      },
      tooltip: {
        enabled: false,
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        const index = chartElement[0].index;
        setHoveredLabel(data.labels[index]);
        setHoveredPercentage(data.datasets[0].data[index]);
      } else {
        setHoveredLabel(null);
        setHoveredPercentage(null);
      }
    },
    cutout: '75%',
  };

  return (
    <>
      <Heading color={'#262626'} fontWeight={700} fontSize={16} pb={'8px'}>
        AI Data Summary - Acquisition to Outcome{' '}
      </Heading>
      <Stack
        direction={'row'}
        height={'95%'}
        width={'100%'}
        position={'relative'}
      >
        <div style={{ position: 'relative', width: '40%', display: 'flex', alignItems: 'center' }}>
          <Doughnut data={data} options={options} />
          {hoveredLabel && hoveredPercentage !== null && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                fontSize: '17px',
                fontWeight: 'bold',
                pointerEvents: 'none',
                color: '#262626',
              }}
            >
              {hoveredLabel} <br /> {hoveredPercentage}%
            </div>
          )}
        </div>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
        >
          <UnorderedList spacing={1}>
            {data.labels.map((label, index) => {
              return (
                <ListItem key={index} display={'flex'}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: data.datasets[0].backgroundColor[index],
                      marginRight: '10px',
                    }}
                  ></div>
                  <Text color={'#262626'} fontWeight={300} fontSize={14}>
                    {label}
                  </Text>
                </ListItem>
              );
            })}
          </UnorderedList>
        </Stack>
      </Stack>
    </>
  );
};

export default AISummary;


function colorSelector(percent) {
  if (percent >= 0 && percent <= 5) {
    return '#166534'; // green
  } else if (percent > 5 && percent <= 10) {
    return '#15803d'; // lighter green
  } else if (percent > 10 && percent <= 15) {
    return '#16a34a'; // yellow/orange
  } else if (percent > 15 && percent <= 20) {
    return '#22c55e'; // yellow/orange
  } else if (percent > 20 && percent <= 25) {
    return '#fbbf24'; // yellow/orange
  } else if (percent > 25 && percent <= 30) {
    return '#f59e0b'; // yellow/orange
  } else if (percent > 30 && percent <= 35) {
    return '#d97706'; // yellow/orange
  } else if (percent > 35 && percent <= 40) {
    return '#dc2626'; // yellow/orange
  } else if (percent > 40 && percent <= 45) {
    return '#b91c1c'; // yellow/orange
  } else if (percent > 45 && percent <= 50) {
    return '#991b1b'; // red
  } else {
    return '#c2f0cb'; // red
  }
}

function colorSelector2(percent) {
  if (percent >= 0 && percent <= 10) {
    return '#22c55e'; // green
  } else if (percent >= 11 && percent <= 20) {
    return '#15803d'; // lighter green
  } else if (percent >= 21 && percent <= 30) {
    return '#f59e0b'; // yellow/orange
  } else if (percent >= 31 && percent <= 40) {
    return '#ef4444'; // yellow/orange
  } else if (percent >= 41 && percent <= 50) {
    return '#dc2626'; // yellow/orange
  } else {
    return '#b91c1c'; // yellow/orange
  }
}