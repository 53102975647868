import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SideWrapper from '../components/SideBar/SideWrapper';
import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Button,
} from '@chakra-ui/react';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon';
import OnBoardButton from '../components/EnterpriseAI/OnBoardButton';
import {
  onBoardButtons,
  VisibilityData,
  sercurityData,
  governanceData,
  hostData as staticHostData,
} from '../utilities/EnterPriseData';
import HostCloudButton from '../components/CloudComponents/HostCloudButton';
import { fetchSupportedVendors } from '../utilities/cloudOnBoardingData';
import SummaryTable from '../components/EnterpriseAI/SummaryTable';
import RiskTable from '../components/EnterpriseAI/RiskTable';
import AISummary from '../components/EnterpriseAI/AISummary';
const EnterPriseAI = () => {
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const [selectedOnBoarding, setSelectedOnBoarding] = useState({});
  const [hostData, setHostData] = useState([]);
  const [selectedHostOption, setSelectedHostOption] = useState(null);

  const getData = async () => {
    let res = await fetchSupportedVendors(errorMsg => {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: errorMsg,
      });
    });

    res.map(item => {
      item['disabled'] = false;

      if (item.key === 'AWS') {
        item['displayFields'] = {
          username: true,
          clientId: true,
          clientSecret: true,
          variables: true,
          region: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'AZURE' || item.key === 'MSTEAMS') {
        item['displayFields'] = {
          clientId: true,
          clientSecret: true,
          subscriptionId: true,
          tenantId: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'ZOOM') {
        item['displayFields'] = {
          clientId: true,
          clientSecret: true,
          tenantId: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'WEBEX') {
        item['displayFields'] = {
          accessToken: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'GCP') {
        item['displayFields'] = {
          configurationFiles: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'OKTA') {
        item['displayFields'] = {
          baseUrl: true,
          variables: true,
          discoveryEnabled: true,
        };
      }
    });

    setHostData(res); // Ensure hostData is an array
  };

  const sortedIcons = staticHostData?.filter(
    item => item.category === selectedOnBoarding?.name
  );

  useEffect(() => {
    // getData();
    console.log('Selected OnBoard', selectedOnBoarding);
  }, [selectedOnBoarding]);

  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LocationHeader followingRoute={[selectedOnBoarding.name]} />
          <NotificationIcon />
        </Stack>

        {/* onBoarding Buttons */}
        <Stack
          direction={'column'}
          gap={'10px'}
          padding={'25px 20px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          spacing={0}
          flexWrap={'wrap'}
        >
          <Heading w="100%" fontSize={'22px'} margin={'0 0 20px 0'}>
            Onboarding
          </Heading>
          <Stack display={'flex'} direction={'row'}>
            {onBoardButtons?.map((v, i) => (
              <OnBoardButton
                name={v?.name}
                key={v?.id}
                index={i}
                selectedOnBoarding={selectedOnBoarding}
                setSelectedOnBoarding={setSelectedOnBoarding}
              />
            ))}
          </Stack>
        </Stack>

        {/* Zone and graph */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={'1%'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          spacing={0}
          // flexWrap={'wrap'}
          width="100%"
        >
          <Box
            flex={1}
            padding={'20px'}
            background={'#fff'}
            direction={'row'}
            alignItems={'center'}
            width="100%"
            height="260px"
            rounded={'md'}
            overflowY={'auto'}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={'8px'}
              flexWrap={'wrap'}
              spacing={0}
              width="100%"
            >
              {sortedIcons?.length > 0
                ? sortedIcons?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      w={selectedOnBoarding.name ? '48%' : '32%'}
                      //   findData={findData}
                      selectedCloudOption={selectedHostOption}
                      setSelectedCloudOption={setSelectedHostOption}
                      isDisabled={v.disabled}
                    />
                  ))
                : staticHostData?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      w={'32%'}
                      //   findData={findData}
                      selectedCloudOption={selectedHostOption}
                      setSelectedCloudOption={setSelectedHostOption}
                      isDisabled={v.disabled}
                    />
                  ))}
            </Stack>
          </Box>
          <Box
            flex={1}
            padding={'20px'}
            background={'#fff'}
            direction={'row'}
            alignItems={'center'}
            width="100%"
            height="260px"
            rounded={'md'}
          >
            <AISummary />
          </Box>
          <Box
            flex={1}
            padding={'20px'}
            background={'#fff'}
            direction={'row'}
            alignItems={'center'}
            width="100%"
            height="260px"
            rounded={'md'}
          >
            <SummaryTable />
          </Box>
        </Stack>

        {/* VSG Data */}
        <Stack
          alignItems={'center'}
          gap={'10px'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          spacing={0}
          flexWrap={'wrap'}
          width="100%"
        >
          <SimpleGrid columns={3} spacingX="20px" width="100%">
            <Box
              background={'#fff'}
              direction={'row'}
              alignItems={'center'}
              width="100%"
              position="relative"
              rounded={'md'}
            >
              <Stack
                padding={'25px'}
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Heading fontWeight={700} fontSize={15}>
                  Visibility
                </Heading>
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  marginTop={-10}
                  marginRight={-10}
                >
                  {' '}
                  {/* Position circle in top right corner */}
                  <Stack
                    rounded={'full'}
                    padding={8}
                    width={18}
                    height={18}
                    bg={'#F57020'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      fontWeight={500}
                      fontSize={14}
                      color={'#fff'}
                      lineHeight={'20px'}
                    >
                      Medium
                    </Text>
                  </Stack>
                </Box>
              </Stack>

              <Stack mt={4} width={'full'}>
                {VisibilityData?.length > 0 && (
                  <TableContainer>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Agent</Th>
                          <Th>Risk</Th>
                          <Th>Application/Service</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {VisibilityData?.map((v, i) => (
                          <Tr key={i} height={'50px'}>
                            <Td>
                              <Image
                                src={v.icon ?? ''}
                                alt={v.agent}
                                height={12}
                                width={12}
                                draggable={false}
                              />
                            </Td>
                            <Td>
                              <Stack
                                borderRadius={8}
                                padding={2}
                                background={
                                  v?.risk === 'Medium'
                                    ? '#FFEEE4'
                                    : v?.risk === 'High'
                                    ? '#FFE4E4'
                                    : '#DFFFE6'
                                }
                              >
                                <Text
                                  textAlign={'center'}
                                  color={
                                    v?.risk === 'Medium'
                                      ? '#F57020'
                                      : v?.risk === 'High'
                                      ? '#F52020'
                                      : '#00E132'
                                  }
                                >
                                  {v?.risk}
                                </Text>
                              </Stack>
                            </Td>
                            <Td
                              alignItems={'center'}
                              justifyContent={'center'}
                              fontWeight={500}
                              color={'#36454F'}
                            >
                              {v.agent}
                            </Td>
                            <Td>
                              <Button
                                background={'var(--blue, #0B57D0)'}
                                color={'#fff'}
                                padding={'4px 8px'}
                                height={'unset'}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                Action
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            </Box>
            <Box
              background={'#fff'}
              direction={'row'}
              alignItems={'center'}
              width="100%"
              position="relative"
              rounded={'md'}
            >
              <Stack
                padding={'25px'}
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Heading fontWeight={700} fontSize={15}>
                  Security
                </Heading>
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  marginTop={-10}
                  marginRight={-10}
                >
                  {' '}
                  {/* Position circle in top right corner */}
                  <Stack
                    rounded={'full'}
                    padding={8}
                    width={18}
                    height={18}
                    bg={'#F52020'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      fontWeight={500}
                      fontSize={14}
                      color={'#fff'}
                      lineHeight={'20px'}
                    >
                      High Risk
                    </Text>
                  </Stack>
                </Box>
              </Stack>

              <Stack mt={4} width={'full'}>
                {sercurityData?.length > 0 && (
                  <TableContainer>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Asset Type</Th>
                          <Th>Risk</Th>
                          <Th>AI Workflow</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sercurityData?.map((v, i) => (
                          <Tr key={i}>
                            <Td>
                              <Image
                                src={v.icon ?? ''}
                                alt={v.agent}
                                height={12}
                                width={12}
                                draggable={false}
                              />
                            </Td>
                            <Td>
                              <Stack
                                borderRadius={8}
                                padding={2}
                                background={
                                  v?.risk === 'Medium'
                                    ? '#FFEEE4'
                                    : v?.risk === 'High'
                                    ? '#FFE4E4'
                                    : '#DFFFE6'
                                }
                              >
                                <Text
                                  textAlign={'center'}
                                  color={
                                    v?.risk === 'Medium'
                                      ? '#F57020'
                                      : v?.risk === 'High'
                                      ? '#F52020'
                                      : '#00E132'
                                  }
                                >
                                  {v?.risk}
                                </Text>
                              </Stack>
                            </Td>
                            <Td
                            whiteSpace={'normal'}
                              maxW={'150px'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              fontWeight={500}
                            >
                              {v.workflow}
                            </Td>
                            <Td>
                              <Button
                                background={'var(--blue, #0B57D0)'}
                                color={'#fff'}
                                padding={'4px 8px'}
                                height={'unset'}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                Action
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            </Box>
            <Box
              background={'#fff'}
              direction={'row'}
              alignItems={'center'}
              width="100%"
              position="relative"
              rounded={'md'}
            >
              <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                padding={'25px'}
              >
                <Heading fontWeight={700} fontSize={15}>
                  Governance/Compliance
                </Heading>
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  marginTop={-10}
                  marginRight={-10}
                >
                  {' '}
                  {/* Position circle in top right corner */}
                  <Stack
                    rounded={'full'}
                    padding={8}
                    width={18}
                    height={18}
                    bg={'#F52020'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      fontWeight={500}
                      fontSize={14}
                      color={'#fff'}
                      lineHeight={'20px'}
                    >
                      High Risk
                    </Text>
                  </Stack>
                </Box>
              </Stack>

              <Stack mt={4} width={'full'}>
                {governanceData?.length > 0 && (
                  <TableContainer>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>AI Agents</Th>
                          <Th>Data</Th>
                          <Th>Infrastructure</Th>
                          <Th>Application</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {governanceData?.map((v, i) => (
                          <Tr key={i}>
                            <Td>
                              {typeof v.agent === 'string' ? (
                                v.agent.match(/\.(jpeg|jpg|gif|png)$/) !=
                                null ? (
                                  <Image
                                    src={v.agent}
                                    alt={v.agent}
                                    height={12}
                                    width={12}
                                    draggable={false}
                                    textAlign={'center'}
                                  />
                                ) : (
                                  <Text
                                    color={'#000'}
                                    fontWeight={700}
                                    wordBreak={'break-word'}
                                    overflow={'hidden'}
                                    whiteSpace={'normal'}
                                  >
                                    {v.agent}
                                  </Text>
                                )
                              ) : (
                                <Text>Invalid data</Text>
                              )}
                            </Td>
                            <Td>
                              <Image
                                src={v.data ?? ''}
                                height={10}
                                width={10}
                                draggable={false}
                              />
                            </Td>
                            <Td>
                              <Image
                                src={v.infra ?? ''}
                                width={28}
                                draggable={false}
                              />
                            </Td>
                            <Td>
                              <Image
                                src={v.application ?? ''}
                                height={8}
                                width={24}
                                draggable={false}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            </Box>
          </SimpleGrid>
        </Stack>

        {/* Evidence - Risk Triage */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          padding={'20px'}
          background={'#fff'}
          spacing={0}
          flexWrap={'wrap'}
          width="100%"
          rounded={'md'}
        >
          <RiskTable />
        </Stack>
      </Stack>
    </SideWrapper>
  );
};

export default EnterPriseAI;
