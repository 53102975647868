import axios from "axios";
import { BaseURL } from "./config";
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';

export const POST = async (url, data = {}, headers = {}, responseDataOnly = true) => {
    try {
        let accessToken = localStorage.getItem('accessToken');
        headers['Authorization'] = accessToken;

        let urlFinal = BaseURL + url;
        if(url == 'api/v1/llmops/graphs') {
            urlFinal = "http://167.172.153.181:7000/api/v1/llmops/graphs";
        }
        const res = await axios.post(
            urlFinal,
            data,
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status >= 200
                }
            }
        );
        if (responseDataOnly) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const PUT = async (url, data = {}, headers = { 'Access-Control-Allow-Origin': true, }, responseDataOnly = true) => {
    try {
        let accessToken = localStorage.getItem('accessToken');
        headers['Authorization'] = accessToken;

        const res = await axios.put(
            BaseURL + url,
            data,
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status >= 200
                }
            }
        );
        if (responseDataOnly) {
            return res.data;
        } else {
            return res;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const GET = async (url, headers = {}) => {
    try {
        let accessToken = localStorage.getItem('accessToken');
        headers['Authorization'] = accessToken;

        const res = await axios.get(
            BaseURL + url,
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status >= 200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const DELETE = async (url, headers = {}) => {
    try {
        let accessToken = localStorage.getItem('accessToken');
        headers['Authorization'] = accessToken;

        const res = await axios.delete(
            BaseURL + url,
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status >= 200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }



}

export const initPushNotification = function (loggedInUserName, onMessageRecieve) {
    var connection = new Client({
        webSocketFactory: function () {
            return new SockJS(BaseURL + 'api/v1/listener/notification/ws')
        },
        connectHeaders: { "host": "seqoria" },
        debug: function (str) {
            console.log(str);
        },

        onStompError: function (frame) {
            console.log(frame)
            throw new Error("Unable to establish push-notification connection");
        },
        reconnectDelay: 50000,
        heartbeatIncoming: 40000,
        heartbeatOutgoing: 40000,
    });
    connection.activate();

    connection.onConnect = function () {

        //Subscribe to connection;
        let subscribeHeaders = {
            "x-queue-name": loggedInUserName,
            "durable": true,
            "auto-delete": false
        };

        var subscribeCallback = function (message) {

            console.log("Push notification message recieved..");
            console.log(message);
            onMessageRecieve(message.body)
        }
        connection.subscribe("/topic/" + loggedInUserName, subscribeCallback, subscribeHeaders);
    }

    return connection;
}

