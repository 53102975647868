import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
} from '@chakra-ui/react'
import { BaseURL } from '../../utilities/config'

export default function NoSslModal({ isOpen, onOpen, onClose, getLicense }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Connection Failure!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>It looks like you are trying to reach an unsecure connection. Are you sure you want to enable unsecure access to requests?</Text>
                </ModalBody>
                <ModalFooter>
                    {/* <Button
                        mr={'10px'}
                        bgColor={'transparent'}
                        color={'#4A4A4A'}
                        padding={'12px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        border={'2px solid #4A4A4A'}
                        _hover={{
                            bgColor: '#4A4A4A',
                            color: '#fff',
                        }}
                        onClick={onClose}>
                        Close
                    </Button> */}
                    <Button
                        as={'a'}
                        target='_blank'
                        onClick={() => {
                            onClose();
                            window.open(`${BaseURL}`, '_blank');
                            getLicense();
                        }}
                        bgColor={'#4A4A4A'}
                        color={'#fff'}
                        padding={'12px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        border={'2px solid #4A4A4A'}
                        _hover={{
                            bgColor: 'transparent',
                            cursor: 'pointer',
                            color: '#4A4A4A',
                        }}
                    >
                        Enable Access!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}