import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Heading, Stack } from '@chakra-ui/react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      stacked: true,
      display: false,
      beginAtZero: true,
      ticks: {
        display: false, // Hides the x-axis ticks and labels
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      categoryPercentage: 0.5, // Adjust category percentage to control space allocation
      barPercentage: 0.5, // Adjust bar percentage to control bar width
      grid: {
        drawBorder: false,
        display: false, // Remove the default y-axis grid lines
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
      barThickness: 2,
      maxBarThickness: 10,
      zIndex: 3,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const labels = [
  'Outcome Impact',
  'Agent - Application Interaction',
  'AI/LLM Agent',
  'AI/LLM Application',
  'Infrastructure',
  'Compliance',
];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [100, 120, 70, 100, 160, 140],
      borderColor: '#33D156',
      backgroundColor: '#33D156',
      borderWidth: 2,
      barThickness: 20,
    },
    {
      label: 'Dataset 2',
      data: [-120, -50, -70, -130, -90, -80],
      borderColor: '#31A54A',
      backgroundColor: '#31A54A',
      borderWidth: 2,
      barThickness: 20,
    },
  ],
};

const SummaryTable = () => {
  return (
    <>
      <Heading color={'#262626'} fontWeight={700} fontSize={16}>
        Responsible AI - Status/Progress{' '}
      </Heading>
      <Bar options={options} data={data} plugins={[drawLinePlugin]} />
    </>
  );
};

export default SummaryTable;

const drawLinePlugin = {
  id: 'drawLinePlugin',
  beforeDatasetsDraw(chart) {
    const { ctx, chartArea: { left, right }, scales: { y } } = chart;
    
    ctx.save();
    ctx.strokeStyle = '#616E7C'; // Set the stroke color for the line
    ctx.lineWidth = 2; // Set the stroke width

    y.ticks.forEach((value, index) => {
      const yPos = y.getPixelForTick(index);

      ctx.beginPath();
      ctx.moveTo(left, yPos);
      ctx.lineTo(right, yPos);
      ctx.stroke();
    });

    ctx.restore();
  }
};

