import { Box, Heading, Icon, Stack, Text, Button, Select, Checkbox, Input } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import LocationHeader from '../components/LocationHeader/index.js';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import CloudOptionButton from '../components/CloudComponents/CloudOptionButton.js';
import { useState, useEffect } from 'react';
import HostCloudButton from '../components/CloudComponents/HostCloudButton.js';
import { useToast } from '@chakra-ui/react';
import CloudLogin from '../components/CloudComponents/CloudLogin.js';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import {
    hostCloud,
    peer2,
    policySolutions,

} from '../utilities/cloudOnBoardingData.js';
import SpacedStack from '../components/SpacedStack/index.js';
import { cloudOptions, peer1, zone1, zone2, zone3 } from "../utilities/neighbor.js";
import NeighborConfigBox from "../components/Neightbor/NeighborBox.js";
import { HiUserAdd } from 'react-icons/hi';
import { ExpiredToken } from '../components/ExpiredToken/ExpiredToken.js';
import { AiOutlinePlus } from 'react-icons/ai';
import AdditionalPeers from '../components/CloudComponents/AdditionalPeers.js';
import { GET, POST, PUT } from '../utilities/ApiProvider.js';
import axios from 'axios';
import { BaseURL } from '../utilities/config.js';
import { Form, Typography } from 'antd';
import { desiredOptions } from '../utilities/desiredOutcomesData.js';
import DesiredButton from '../components/DesiredOutcomes/DesiredButtons.js';
import Amazon from '../assets/images/companies/amazon.png'
import AmazonWhite from '../assets/images/companies/amazon-white.png'
import Google from '../assets/images/companies/google.png'
import GoogleWhite from '../assets/images/companies/google-white.png'
import Azure from '../assets/images/companies/azure.png'
import AzureWhite from '../assets/images/companies/azure-white.png'
import ListNeighbor from '../components/Neightbor/ListNeighbor.js';
import salesForce from '../assets/images/salesForce.png'
import sap from '../assets/images/sap.png'
import oracle from '../assets/images/oracle.png'

export default function NeighborConfig() {
    let orgId = localStorage.getItem('orgId');
    const navigation = useNavigate();
    const [isCreatingZone, setIsCreatingZone] = useState(true);
    const [user, setUser] = useState({});
    const [selectedCloudOption, setSelectedCloudOption] = useState('Zone 0');
    const [apiData, setApiData] = useState(null);
    const apiUrl = 'https://144.126.248.16.nip.io/api/v1/worker/supported-vendor';
    const [hostData, setHostData] = useState([]);
    // const [optionsList] = useState(['Host Cloud', 'Peer 1', 'Peer 2']);
    const [filterData, setFilterData] = useState({});
    const [value, setValue] = useState({
        name: '',
        iconPath: '',
        supportedVendorId: '',
        configurations: {
            host: {
                credentials: {
                    configuraitonId: '',
                    username: '',
                    password: '',
                    clientSecret: '',
                    clientId: '',
                    tenantId: '',
                    subscriptionId: '',
                    accessToken: '',
                    variables: {
                        rgn: '',
                        format: '',
                    },
                    configurationFiles: {
                        rgn: '',
                        name: '',
                        content: '',
                    },
                },
            },
        },
    });
    const [selectedHostOption, setSelectedHostOption] = useState(null);
    const [selectedPeer1Option, setSelectedPeer1Option] = useState(null);
    const [selectedPeer2Option, setSelectedPeer2Option] = useState(null);
    const [selectedPeer3Option, setSelectedPeer3Option] = useState(null);
    const [selectZone1, setSelectZone1] = useState('');
    const [selectZone2, setSelectZone2] = useState('');
    const [selectZone3, setSelectZone3] = useState('');
    const [selectedDesireOption, setSelectedDesireOption] = useState('');
    const [data, setData] = useState([]);
    const selector = useSelector(state => state);
    const token =
        'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJrM2tTeVZoZmhuTDVRZjliWTNVaUVzQk5fX0hvVG1nb1J5bHpIaGEwdkRVIn0.eyJleHAiOjE2OTUyMDE1MjYsImlhdCI6MTY5NTIwMTIyNiwianRpIjoiYzI4OGI0MzktNmNjOC00MGQ1LWE0ZmMtOGQ5OWVhODMwMDc2IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mb3ItdGhlLmJpei9yZWFsbXMvY2lwaGVyc3RhY2siLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNjQ3MmE2YjEtZmI2ZC00YTg1LWI0ZjAtYTlkMjkwY2MyZjcxIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiY2lwaGVyc3RhY2stY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImY0YzU4NTQ1LWMyYmUtNGU4My1hM2YxLTYxOGUxZmU2YTA2MSIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo3ODAwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWNpcGhlcnN0YWNrIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJzaWQiOiJmNGM1ODU0NS1jMmJlLTRlODMtYTNmMS02MThlMWZlNmEwNjEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkNpcGhlcnN0YWNrIFVzZXIiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJjaXBoZXJzdGFja3VzZXIiLCJnaXZlbl9uYW1lIjoiQ2lwaGVyc3RhY2siLCJmYW1pbHlfbmFtZSI6IlVzZXIifQ.MiyxA3ncUqnnE1w5mcCNcP7CBoNA-hcqbH68KEYgKMGY4qQA-kPsQeCtJZz6u7hClS9v9Tz8sGbvbKlnZQUteKYGMIaW5CERT5sW_rF589_rIknq_rXsCEAKqgEcR47UxTS7OSEcn0sJ_I1fegNzXhmtUIvbjOCpmmhWKlamI96Ry7pl57POT9ewNdcFwi74TF6CYSmVI5tVXaybbqjx7ycZomeSpJtH-0z2ZGb4e3zX_YT-VpIwYrMSDZvgqQzm-NFH82xyE5jR2tC5X8LQfU42tR03Qak3f0ML1B9Tg__39VLgiruy-KJQVO_lKkSSJ0wOBNpXNJdkbtHKMAzulQ';
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    const navigate = useNavigate();
    const toast = useToast();

    const getData = async () => {
        if (localStorage.getItem('accessToken')) {
            try {
                const res = await GET(`api/v1/tenant/supported-vendor/`);
                setHostData(Array.isArray(res) ? res.filter(val => val?.policyDisabled === null) : [res]); // Ensure hostData is an array
            } catch (error) {
                // Handle errors
            }
        } else {

        }
    };

    const getVenderManagement = async () => {
        if (localStorage.getItem('accessToken')) {
            const res = await GET(`api/v1/tenant/vendor/`, {

            });
            setData(res);
        } else {
            toast({
                position: 'bottom-left',
                isClosable: true,
                duration: 5000,
                status: 'error',
                description: 'Access token is missing',
            });
        }
    };

    const findData = val => {
        console.log(val)
        console.log(data)

        setValue({
            ...value,
            name: val.name,
            iconPath: val.iconPath,
            supportedVendorId: val.id,
        });
        const find = data.find(item => {
            return item?.configurations?.host?.supportedVendorId == val;
        });
        console.log(find)
        setFilterData(find);
    };

    useEffect(() => {
        if (selector) {
            setUser(selector.value);
        }
    }, [selector]);

    useEffect(() => {
        if (user) {
            getData();
            getVenderManagement();
        }
    }, [user]);

    const listOfLocalImages = [
        { image: Amazon, imageWhite: AmazonWhite },
        { image: Google, imageWhite: GoogleWhite },
        { image: Azure, imageWhite: AzureWhite },
    ]

    const [optionsList] = useState(['Zone', 'Zone 1', 'Zone 2', 'Zone 3', 'Additional Zone']);

    // FROM FEBRUARY

    const [neighborOrg, setNeighborOrg] = useState(null);
    const [orgDevices, setOrgDevices] = useState('');
    const [neighborAdj, setNeighborAdj] = useState(null);
    const [adjDevices, setAdjDevices] = useState('');
    const [neighborTra, setNeighborTra] = useState(null);
    const [traDevices, setTraDevices] = useState('');
    const [neighborDes, setNeighborDes] = useState(null);
    const [desDevices, setDesDevices] = useState('');


    const [neighborRelData, setNeighborRelData] = useState([]);
    const [relationshipData, setRelationshipData] = useState({
        originator: "",
        adjacent: "",
        transient: "",
        destination: "",
        tenantId: orgId ?? "ABC123"
    });
    const [isLoading, setIsLoading] = useState(false);
    const [neighborName, setNeighborName] = useState('');
    const [options, setOptions] = useState({
        override: false,
        trackLineage: false
    })

    const neighborRelPostHandler = async () => {
        setIsLoading(true);
        let data = {
            name: neighborName,
            tenantId: orgId,
            originatorConfigurationId: relationshipData.originator,
            adjacentConfigurationId: relationshipData.adjacent,
            transientConfigurationId: relationshipData.transient,
            destinationConfigurationId: relationshipData.destination,
            zoneDevices: [{
                deviceDetails: null,
                customerDeviceId: orgDevices,
                neighbourRelation: "originator",
                vendor: String(relationshipData?.originator).split('-')[0].trim(),
            }, {
                deviceDetails: null,
                customerDeviceId: adjDevices,
                neighbourRelation: "adjacent",
                vendor: String(relationshipData?.adjacent).split('-')[0].trim(),
            }, {

                deviceDetails: null,
                customerDeviceId: traDevices,
                neighbourRelation: "transient",
                vendor: String(relationshipData?.transient).split('-')[0].trim(),
            }, {
                deviceDetails: null,
                customerDeviceId: desDevices,
                neighbourRelation: "destination",
                vendor: String(relationshipData?.destination).split('-')[0].trim(),
            }],
            ...options
        }
        POST('api/v1/worker/neighborhood-zone', data, {}, false).then(function (response) {
            console.log(JSON.stringify(response));
            if (response.status == 200) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'success',
                    description: 'Neighbor Relationship Saved!',
                });
            } else {
                response.data.errors.forEach(function (error) {
                    toast({
                        position: 'bottom-left',
                        isClosable: true,
                        duration: 5000,
                        status: 'error',
                        description: error.message,
                    });
                })
            }
        })
            .catch(function (error) {
                console.log(error);
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Error Creating Zone.',
                });
                console.log(error);
            });

        setIsLoading(false);
    }

    const getRelations = async () => {
        if (localStorage.getItem('accessToken')) {
            try {
                const res = await GET(`api/v1/worker/vendors/AWS,AZURE,GCP`);
                setNeighborRelData(res);
            } catch (error) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Neighbor Relations not found!',
                });
            }
        } else {

        }
    };

    const getDevices = async (value, identifier) => {
        if (localStorage.getItem('accessToken')) {
            try {
                let name = String(value).split('-')[0].trim();
                let id = String(value).split('-')[1].trim();
                const res = await GET(`api/v1/worker/devices/vendor/${name}/config/${id}`);
                https://apigateway.for-the.biz/api/v1/worker/devices/vendor/AWS/config/65ccde9813e2f0174f5d685f
                if (res) {
                    if (identifier === 'org') {
                        setNeighborOrg(res?.devices)
                    } else if (identifier === 'adj') {
                        setNeighborAdj(res?.devices)
                    } else if (identifier === 'tra') {
                        setNeighborTra(res?.devices)
                    } else if (identifier === 'des') {
                        setNeighborDes(res?.devices)
                    } else {

                    }
                }
            } catch (error) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Neighbor Relations not found!',
                });
            }
        } else {

        }
    };

    useEffect(() => {
        if (user) {
            getRelations();
        }
    }, [user]);

    return (
        <SideWrapper>
            <Stack w={'full'} margin={'40px 50px 30px 35px !important'}>
                {/* Current Location identifier */}
                <Stack direction="row" justifyContent="space-between">
                    <LocationHeader
                        optionsList={optionsList}
                        followingRoute={[
                            selectedHostOption?.name,
                            selectedPeer1Option?.data,
                            selectedPeer2Option?.data,
                            selectedPeer3Option?.data,
                        ]}
                    />
                    <Stack direction="row">
                        <Button onClick={() => setIsCreatingZone(true)} bg={"transparent"} color={"primary.100"} _hover={"none"} fontWeight={'600'} border={"1px solid gray"} padding={"25px 30px"}>Add-Discovery-Creation</Button>
                        <Button onClick={() => setIsCreatingZone(true)} bg={"transparent"} color={"primary.100"} _hover={"none"} fontWeight={'600'} border={"1px solid gray"} width={'150px'} padding={"25px 30px"}>Create</Button>
                        <Button onClick={() => setIsCreatingZone(false)} bg={isCreatingZone ? "transparent" : "primary.100"} color={isCreatingZone ? "primary.100" : "#fff"} _hover={"none"} fontWeight={'600'} border={"1px solid gray"} width={'150px'} padding={"25px 30px"}>List</Button>
                        <NotificationIcon />
                    </Stack>
                </Stack>

                {
                    isCreatingZone ?
                        <>
                            <SpacedStack>
                                <>
                                    <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                                        Zone
                                    </Heading>
                                    <Stack
                                        className='zoneStack'
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={'10px'}
                                        flexWrap={'wrap'}
                                        spacing={0}
                                    >
                                        {hostData?.length > 0 &&
                                            hostData?.map((v, i) => (
                                                <HostCloudButton
                                                    {...v}
                                                    key={i}
                                                    w="16%"
                                                    findData={findData}
                                                    selectedCloudOption={selectedHostOption}
                                                    setSelectedCloudOption={setSelectedHostOption}
                                                />
                                            ))}
                                        <HostCloudButton
                                            name={'SAP'}
                                            image={sap}
                                            imageWhite={sap}
                                            findData={findData}
                                            selectedCloudOption={selectedHostOption}
                                            setSelectedCloudOption={setSelectedHostOption}
                                        />
                                        <HostCloudButton
                                            name={'SalesForce'}
                                            image={salesForce}
                                            imageWhite={salesForce}
                                            findData={findData}
                                            selectedCloudOption={selectedHostOption}
                                            setSelectedCloudOption={setSelectedHostOption}
                                        />
                                        <HostCloudButton
                                            name={'Oracle'}
                                            image={oracle}
                                            imageWhite={oracle}
                                            findData={findData}
                                            selectedCloudOption={selectedHostOption}
                                            setSelectedCloudOption={setSelectedHostOption}
                                        />
                                        <HostCloudButton
                                            noImage={true}
                                            name="Saas/Partner"
                                            w="16%"
                                            id="3"
                                            findData={findData}
                                            selectedCloudOption={selectedHostOption}
                                            setSelectedCloudOption={setSelectedHostOption}
                                        />
                                        <HostCloudButton
                                            noImage={true}
                                            name="Third-Party Tools"
                                            w="16%"
                                            id="4"
                                            findData={findData}
                                            selectedCloudOption={selectedHostOption}
                                            setSelectedCloudOption={setSelectedHostOption}
                                        />

                                    </Stack>

                                </>
                            </SpacedStack>

                            {
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    padding={'15px 30px 35px'}
                                    background={'#fff'}
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    flexWrap={'wrap'}
                                >
                                    <Text w={'100%'} lineHeight={"48px"} fontWeight={"700"} fontSize={"20px"}>Generative/AI Workflow</Text>
                                    {desiredOptions.map(v => (
                                        <DesiredButton
                                            {...v}
                                            selectedDesireOption={selectedDesireOption}
                                            setSelectedDesireOption={setSelectedDesireOption}
                                        />
                                    ))}
                                </Stack>
                            }
                            {
                                selectedCloudOption !== '' &&
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    padding={'35px 30px'}
                                    background={'#fff'}
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    flexWrap={"wrap"}
                                    overflow={'hidden'}
                                >
                                    <Stack mb={"22px"} w="100%" direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Text fontWeight={"600"} fontSize={"25px"}>Neighbor Relationship</Text>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'flex-end'} gap="40px" mb="10px !important" justifyContent={'space-between'} w="100%">
                                        <Stack w="50%">
                                            <Typography.Text className="formLabel">Name</Typography.Text>
                                            <Input value={neighborName} onChange={e => setNeighborName(e.target.value)} />
                                        </Stack>
                                        <Stack w="25%">
                                            <Checkbox value={options.override} onChange={e => setOptions({
                                                ...options,
                                                override: e.target.checked
                                            })}><Typography.Text style={{ fontSize: '15px' }} className="formLabel">Override / Decom</Typography.Text></Checkbox>
                                        </Stack>
                                        <Stack w="25%">
                                            <Checkbox value={options.trackLineage} onChange={e => setOptions({
                                                ...options,
                                                trackLineage: e.target.checked
                                            })}><Typography.Text style={{ fontSize: '15px' }} className="formLabel">Track Lineage</Typography.Text></Checkbox>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={'20px'}
                                        background={'#fff'}
                                        borderRadius={'10px'}
                                        marginBottom={'20px !important'}
                                        spacing={0}
                                        flexWrap={"wrap"}
                                        width="100%"
                                        overflow={'hidden'}
                                        className='neighborRelStack'
                                    >

                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Originator</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => {
                                                    getDevices(e.target.value, 'org');
                                                    setRelationshipData({
                                                        ...relationshipData,
                                                        originator: e.target.value
                                                    })
                                                }}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborRelData?.length > 0 &&
                                                    neighborRelData?.map(val =>
                                                        <option w="32%" value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Adjecent</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => {
                                                    getDevices(e.target.value, 'adj');
                                                    setRelationshipData({
                                                        ...relationshipData,
                                                        adjacent: e.target.value
                                                    })
                                                }}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborRelData?.length > 0 &&
                                                    neighborRelData?.map(val =>
                                                        <option w="32%" value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Transient</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => {
                                                    getDevices(e.target.value, 'tra');
                                                    setRelationshipData({
                                                        ...relationshipData,
                                                        transient: e.target.value
                                                    })
                                                }}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborRelData?.length > 0 &&
                                                    neighborRelData?.map(val =>
                                                        <option w="32%" value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Destination</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => {
                                                    getDevices(e.target.value, 'des');
                                                    setRelationshipData({
                                                        ...relationshipData,
                                                        destination: e.target.value
                                                    })
                                                }}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborRelData?.length > 0 &&
                                                    neighborRelData?.map(val =>
                                                        <option w="32%" value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>

                                    </Stack>
                                </Stack>
                            }
                            {
                                selectedCloudOption !== '' &&
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    padding={'35px 30px'}
                                    background={'#fff'}
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    flexWrap={"wrap"}
                                    overflow={'hidden'}
                                >
                                    <Text mb={"22px"} fontWeight={"600"} fontSize={"25px"}>Device / Services</Text>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={'20px'}
                                        background={'#fff'}
                                        borderRadius={'10px'}
                                        marginBottom={'20px !important'}
                                        spacing={0}
                                        flexWrap={"wrap"}
                                        width="100%"
                                        overflow={'hidden'}
                                        className='neighborRelStack'
                                    >

                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Originator</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => setOrgDevices(e.target.value)}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborOrg?.length > 0 &&
                                                    neighborOrg?.map(val =>
                                                        <option value={val}>{val}</option>
                                                    )
                                                }
                                            </Select >
                                        </Stack >
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Adjecent</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => setAdjDevices(e.target.value)}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborAdj?.length > 0 &&
                                                    neighborAdj?.map(val =>
                                                        <option value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Transient</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => setTraDevices(e.target.value)}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborTra?.length > 0 &&
                                                    neighborTra?.map(val =>
                                                        <option value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>
                                        <Stack w={'32%'}>
                                            <Typography.Text className="formLabel">Destination</Typography.Text>
                                            <Select
                                                _focusVisible={{ outline: 'none' }}
                                                border={'1px solid #d9d9d9'}
                                                borderRadius={8}
                                                height={'50px'}
                                                onChange={e => setDesDevices(e.target.value)}
                                            >
                                                <option style={{ display: 'none' }} value={''}>Select</option>
                                                {
                                                    neighborDes?.length > 0 &&
                                                    neighborDes?.map(val =>
                                                        <option value={val}>{val}</option>
                                                    )
                                                }
                                            </Select>
                                        </Stack>

                                    </Stack >
                                </Stack >
                            }
                            {
                                selectedCloudOption !== '' &&
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    // padding={'35px 30px'}
                                    // background={'#fff'}
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    flexWrap={"wrap"}
                                    overflow={'hidden'}
                                >
                                    <Box className='neighborBtns' display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                                        {/*  onClick={neighborRelPostHandler} onClick={neighborRelPutHandler} */}
                                        <Button isLoading={isLoading} onClick={neighborRelPostHandler} bg={"primary.100"} color={"white"} _hover={"none"} fontWeight={'600'} m={"5px"} border={"1px solid gray"} width={"260px"} padding={"25px 30px"} isDisabled={neighborRelData === null ? true : false}>Create</Button>
                                        {/* <Button isLoading={isLoading} onClick={neighborRelPutHandler} bg={"white"} fontWeight={'600'} m={"5px"} border={"1px solid gray"} width={"30%"} padding={"25px 30px"} isDisabled={neighborRelData === null ? true : false}>Update</Button> */}
                                        {/* <Button bg={"white"} fontWeight={'600'} m={"5px"} border={"1px solid gray"} width={"30%"} padding={"25px 30px"}>Activate</Button> */}
                                    </Box>
                                </Stack>
                            }
                        </>
                        :
                        <ListNeighbor />
                }

            </Stack >
        </SideWrapper >
    );
}