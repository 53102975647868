import React, { useEffect, useState, useCallback } from 'react';
import { Select } from 'antd';
import {
  Handle,
  Position,
  useReactFlow,
  useNodesInitialized,
  useStore,
} from 'reactflow';
import { POST } from '../../utilities/ApiProvider';
import { Image, Stack, Text, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import cloudzone from '../../assets/images/cloudzone.png';
import Amazon from '../../assets/images/companies/amazon.png';
import Google from '../../assets/images/companies/google.png';
import Azure from '../../assets/images/companies/azure.png';
import Webex from '../../assets/images/companies/webex.png';
import Microsoft from '../../assets/images/companies/microsoft.png';
import Zoom from '../../assets/images/companies/zoom.png';
import SAP from '../../assets/images/sap.png';
import Okta from '../../assets/images/companies/okta.png';
import Oracle from '../../assets/images/oracle.png';
import Salesforce from '../../assets/images/salesForce.png';
import PALOALTO from '../../assets/images/companies/paloalto.png';
import InputNode from './InputNode';

const options = {
  includeHiddenNodes: false,
};
const fitviewOption = {
  includeHiddenNodes: true,
  duration: 0.1,
};

const hostCloud = [
  { name: 'Amazon Web Services', image: Amazon, edgeColor: '#F7A80D' },
  { name: 'Google Cloud Services', image: Google, edgeColor: '#EA4335' },
  { name: 'Microsoft Azure', image: Azure, edgeColor: '#0089D6' },
  { name: 'Microsoft Teams', image: Microsoft, edgeColor: '' },
  { name: 'WebEx', image: Webex, edgeColor: '' },
  { name: 'Zoom', image: Zoom, edgeColor: '' },
  { name: 'Okta', image: Okta, edgeColor: '' },
  { name: 'SAP', image: SAP, edgeColor: '#005AAE' },
  { name: 'Oracle', image: Oracle, edgeColor: '#FF141D' },
  { name: 'Salesforce', image: Salesforce, edgeColor: '' },
  { name: 'Palo Alto', image: PALOALTO, edgeColor: '#F04E23' },
];

const NeighborSelect = ({ data, id }) => {
  const nodesInitialized = useNodesInitialized(options);
  const [animated, setAnimated] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [createdNodes, setCreatedNodes] = useState([]);
  const [createdEdges, setCreatedEdges] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const reactFlowInstance = useReactFlow();

  const label = useStore(s => {
    const node = s.nodeInternals.get(id);

    if (!node) {
      return null;
    }

    return `position x:${parseInt(node.position.x)} y:${parseInt(
      node.position.y
    )}`;
  });

  const onChange = useCallback(
    async evt => {
      let value = JSON.parse(evt);
      setSelectedValue(value?.name);
      let selectedName = JSON.parse(evt);
      console.log('Evt Value', JSON.parse(evt));
      let totalNodeValue = reactFlowInstance.getNodes();
      if (totalNodeValue.length > 1 && data?.isZone) {
        console.log('Delete Nodes');
        const nodePayload = reactFlowInstance
          .getNodes()
          .filter(node => node.id !== '1')
          .map(node => ({ id: node.id }));
        const edgePayload = reactFlowInstance
          .getEdges()
          .map(edge => ({ id: edge.id }));
        reactFlowInstance.deleteElements({
          nodes: nodePayload,
          edges: edgePayload,
        });
        setCreatedNodes([]);
        setCreatedEdges([]);
        if (data?.isZone) {
          data?.setConditionMet(false);
          createNodes(value?.edgeColor);
        } else {
          console.log('Selected Name', selectedName, id);
          reactFlowInstance.setNodes(nodes =>
            nodes.map(node => {
              if (node.id === id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedValue: selectedName,
                  },
                };
              }
              return node;
            })
          );
        }
      } else {
        if (data?.isZone) {
          createNodes(value?.edgeColor);
        } else {
          console.log('Selected Name', selectedName, id);
          reactFlowInstance.setNodes(nodes =>
            nodes.map(node => {
              if (node.id === id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedValue: selectedName,
                  },
                };
              }
              return node;
            })
          );
        }
      }
    },
    [createdNodes, createdEdges]
  );

  // Create Name Input Node
  const createNodes = color => {
    console.log('COLOR', color);
    setisLoading(true);
    let newIds = [];
    let newEdgeIds = [];
    let totalIds = reactFlowInstance.getNodes().length; // Get the len
    console.log(totalIds, 'Total Nodes');
    const id = `${++totalIds}`;
    console.log(id, 'Input Id');
    newIds.push(id); // Collect new ID
    const newNode = {
      id: '2',
      type: 'InputUpdater',
      position: { x: 365, y: 379 },
      data: {
        placeholder: 'Neighbor Relationship Name',
        currentId: id,
        edgeColor: color,
        setCreatedNodes: setCreatedNodes,
        setCreatedEdges: setCreatedEdges,
      },
    };
    // const newNodes = newIds.map(id => ({
    //   id,
    //   type: 'InputUpdater',
    //   position: { x: 150, y: 350 },
    //   data: {
    //     placeholder: 'Neighbor Relationship Name',
    //   },
    // }));
    reactFlowInstance.addNodes(newNode);
    const newEdge = {
      id: `edge-${id}`,
      source: '1',
      target: '2',
      sourceHandle: 'a',
      style: {
        strokeWidth: 2,
        stroke: color,
      },
    };
    newEdgeIds.push(`edge-${id}`);
    reactFlowInstance.addEdges(newEdge);
    setCreatedNodes(newIds);
    setCreatedEdges(newEdgeIds);
    setisLoading(false);
  };

  useEffect(() => {
    // hack to avoid animation on first render; for some reason nodes were animating from position 0
    // to their initial position
    setAnimated(true);
  }, []);

  return (
    <motion.div
      className="animated-node"
      layout={animated}
      // create new component when animated changes, see issue workaround https://github.com/framer/motion/issues/2238#issue-1809290539
      key={data?.id?.concat(animated.toString())}
    >
      <div className="select-updater-node nodrag">
        {/* <Handle type="target" position={Position.Left} /> */}
        <div>
          {data?.isZone ? (
            <img
              src={
                hostCloud.find(host => host.name === selectedValue)?.image ||
                data?.img ||
                cloudzone
              }
              alt="selectImage"
              height={40}
              width={selectedValue ? 100 : 50}
              objectFit={'contain'}
              draggable="false"
            />
          ) : (
            <label htmlFor="text"> {data?.placeholder}: </label>
          )}
        </div>
        {/* <Handle type="source" position={Position.Bottom} id="a" /> */}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '110%',
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        <Handle
          type="source"
          position={Position.Right}
          id="b"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
        {data?.isZone ? (
          <Select
            size="sm"
            placeholder={data?.placeholder || 'Select option'}
            id="text"
            name="text"
            onChange={onChange}
            className="nodrag"
            style={{ width: 'fit-content' }}
            disabled={isLoading}
            value={selectedValue || undefined}
          >
            {data?.values?.map((val, ind) => {
              const matchedHost = hostCloud.find(
                host => host.name === val?.name
              );
              const imageSrc = matchedHost ? matchedHost.image : null;
              const edgeColor = matchedHost ? matchedHost.edgeColor : null;
              return (
                <option
                  key={ind}
                  value={JSON.stringify({ ...val, edgeColor }) || val}
                >
                  <Stack display={'flex'} direction={'row'} gap={3}>
                    {imageSrc && (
                      <Image
                        src={imageSrc}
                        w={'120px'}
                        height={'30px'}
                        objectFit={'contain'}
                      />
                    )}
                  </Stack>
                </option>
              );
            })}
          </Select>
        ) : (
          <Select
            size="sm"
            placeholder={data?.placeholder || 'Select option'}
            id="text"
            name="text"
            onChange={onChange}
            className="nodrag"
            style={{ width: 'fit-content' }}
            disabled={isLoading}
            value={selectedValue || undefined}
          >
            {data?.values?.map((val, ind) => (
              <option w="32%" key={ind} value={JSON.stringify(val) || val}>
                {val?.name ? val?.name : val}
              </option>
            ))}
          </Select>
        )}
        {/* {label || 'no node connected'} */}
        <Handle
          type="target"
          position={Position.Left}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
      </div>
    </motion.div>
  );
};

export default NeighborSelect;
